import { ApolloQueryResult } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { DocumentStatus, ReportDocumentDetailQuery, ReportDocumentDetailQueryVariables, useWatchLazyQuery } from '~anyx/common/graphql';

import {
  REPORT_DOCUMENT_DETAIL,
} from '../graphql';

interface DownloadDocumentProps {
  onProcessStart?: () => void;
  onSuccess?: () => void;
  onComplete?: () => void;
  onFailure?: (error?: unknown) => void;
  handleDocumentDownloadUrl: (url: string) => void;
  pollIntervalInMs?: number;
}

export function useDownloadReportDocument({
  onProcessStart,
  onSuccess,
  onComplete,
  onFailure,
  handleDocumentDownloadUrl,
  pollIntervalInMs = 5000,
}: DownloadDocumentProps) {
  const onSuccessCallback = ({
    data: queryResult,
  }: ApolloQueryResult<ReportDocumentDetailQuery>) => {
    const {
      reportDocument: { id, status, url },
    } = queryResult;

    switch (status) {
      case DocumentStatus.SUCCESS:
        if (!url) {
          Sentry.captureException(`Encountered Success State with Empty URL for Document Id ${id}`);
          onFailure?.();
          return true;
        }

        handleDocumentDownloadUrl(url);
        onSuccess?.();
        return true;

      case DocumentStatus.FAIL:
        onFailure?.();
        return true;

      default:
        return false;
    }
  };

  const onErrorCallback = (error: unknown) => {
    onFailure?.(error);
  };

  const { listenToWatchedLazyQuery } = useWatchLazyQuery<
    ReportDocumentDetailQuery,
    ReportDocumentDetailQueryVariables
  >({
    query: REPORT_DOCUMENT_DETAIL,
    options: {
      pollInterval: pollIntervalInMs,
    },
  });

  const handleDownloadDocument = (queryVariables: ReportDocumentDetailQueryVariables) => {
    onProcessStart?.();
    listenToWatchedLazyQuery(queryVariables, onSuccessCallback, onErrorCallback, () => {
      onComplete?.();
    });
  };

  return {
    handleDownloadDocument,
  };
}
