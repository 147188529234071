import { gql } from '~anyx/common/graphql';

export const AMAZON_SALES_DETAILED_REPORT = gql(/* GRAPHQL */ `
  query AmazonSalesDetailedReport($input: AmazonSalesDetailedReportInput!) {
    amazonSalesDetailedReport(input: $input) {
      items {
        averageNumberOfUnitsPerOrderItem
        currency
        date
        itemAverageCost
        itemsOrdered
        sales
        unitAverageCost
        unitsOrdered
      }
      meta {
        length
        limit
        start
        total
      }
      total {
        averageNumberOfUnitsPerOrderItem
        currency
        itemAverageCost
        itemsOrdered
        sales
        unitAverageCost
        unitsOrdered
      }
    }
  }
`);
