import { gql } from '~anyx/common/graphql';

export const AD_DATASOURCES_LIST = gql(/* GRAPHQL */ `
  query AdDatasourcesList($input: AdDatasourcesInput!) {
    adDatasources(input: $input) {
      items {
        config {
          key
          value
        }
        createdAt
        createdBy
        id
        masterDataStoreId
        name
        nextSyncDateTime
        syncDateTime
        syncStatus
        type
      }
    }
  }
`);
