import { gql } from '~anyx/common/graphql';

export const TIKTOK_SALES_REPORT_CHART = gql(/* GRAPHQL */ `
  query TikTokSalesReportChart($input: TikTokSalesReportChartInput!) {
    tikTokSalesReportChart(input: $input) {
      currency
      itemsOrdered
      sales
      unitsOrdered
    }
  }
`);
