import { gql } from '~anyx/common/graphql';

export const SHOPEE_SALES_CHART = gql(/* GRAPHQL */ `
  query ShopeeSalesChart($input: ShopeeSalesChartInput!) {
    shopeeSalesChart(input: $input) {
      currency
      orders
      sales
      salesPerOrder
      visitors
    }
  }
`);
