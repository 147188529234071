import { Trans, useTranslation } from 'react-i18next';

import { Anchor } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';

import { AlertBanner } from '~anyx/shared/ui';
import { AnyXLanguage, DocUtils } from '~anyx/shared/utils';

const STORAGE_KEY = 'csv-template-update-dismissed';

export const CsvUpdateBannerTmp = () => {
  const { t } = useTranslation('core');
  const [dismissed, setDismissed] = useLocalStorage({
    key: STORAGE_KEY,
    defaultValue: false,
  });

  const handleDismiss = () => {
    setDismissed(true);
    localStorage.setItem(STORAGE_KEY, 'true');
  };

  if (dismissed) {
    return null;
  }

  return (
    <AlertBanner
      type="info"
      title={t('core.page.home.csvUpdateBanner.title')}
      withCloseButton
      onClose={handleDismiss}
      className="mb-4"
    >
      <Trans
        i18nKey="core.page.home.csvUpdateBanner.content"
        ns="core"
        components={[
          <Anchor
            href={DocUtils.getGitbookLink('/products/products/csv', {
              [AnyXLanguage.JA_JP]: '/products/product-bulk',
            })}
            size="sm"
          />,
        ]}
        parent="p"
      />
    </AlertBanner>
  );
};
