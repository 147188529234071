import { gql } from '~anyx/common/graphql';

export const DATASOURCES_LIST = gql(/* GRAPHQL */ `
  query DatasourcesList(
    $pageNumber: Int!
    $pageSize: Int!
    $filter: DatasourceFilterInput
    $sortBy: DatasourceSortByInput
  ) {
    allDatasources(pageNumber: $pageNumber, pageSize: $pageSize, filter: $filter, sortBy: $sortBy) {
      items {
        masterDataStoreId
        createdAt
        id
        isActive
        setting
        status
        statusDetail
        tokenId
        type
        updatedAt
      }
      meta {
        total
      }
    }
  }
`);
