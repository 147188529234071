import { gql } from '~anyx/common/graphql';

export const ONBOARDING_CREATE_STORE = gql(/* GraphQL */ `
  mutation OnboardingCreateStore($input: MdCreateStoreInput!) {
    MdCreateStore(input: $input) {
      id
      name
    }
  }
`);
