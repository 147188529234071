import { gql } from '~anyx/common/graphql';

export const LAZADA_SALES_DETAILED_REPORT = gql(/* GRAPHQL */ `
  query LazadaSalesDetailedReport($input: LazadaSalesDetailedReportInput!) {
    lazadaSalesDetailedReport(input: $input) {
      items {
        conversionRate
        currency
        date
        orders
        sales
        unitsOrdered
        visitors
      }
      meta {
        length
        limit
        start
        total
      }
      total {
        conversionRate
        currency
        orders
        sales
        unitsOrdered
        visitors
      }
    }
  }
`);
