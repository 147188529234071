import { gql } from '~anyx/common/graphql';

export const TOKOPEDIA_SALES_SUMMARY = gql(/* GRAPHQL */ `
  query TokopediaSalesSummary($input: TokopediaSalesSummaryInput!) {
    tokopediaSalesSummary(input: $input) {
      currency
      orders {
        diff
        diffPercentage
        value
      }
      sales {
        diff
        diffPercentage
        value
      }
    }
  }
`);
