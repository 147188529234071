import { gql } from '~anyx/common/graphql';

export const LAZADA_SALES_SUMMARY = gql(/* GRAPHQL */ `
  query LazadaSalesSummary($input: LazadaSalesSummaryInput!) {
    lazadaSalesSummary(input: $input) {
      conversionRate {
        diff
        diffPercentage
        value
      }
      currency
      orders {
        diff
        diffPercentage
        value
      }
      sales {
        diff
        diffPercentage
        value
      }
      unitsOrdered {
        diff
        diffPercentage
        value
      }
      visitors {
        diff
        diffPercentage
        value
      }
    }
  }
`);
