import { gql } from '~anyx/common/graphql';

export const SHOPIFY_SALES_REPORT_TABLE = gql(/* GRAPHQL */ `
  query ShopifySalesReportTable($input: ShopifySalesReportTableInput!) {
    shopifySalesReportTable(input: $input) {
      items {
        currency
        date
        discounts
        grossSales
        netSales
        orderCount
        returns
        shipping
        taxes
        totalSales
      }
      meta {
        length
        limit
        start
        total
      }
      total {
        discounts
        grossSales
        netSales
        orderCount
        returns
        shipping
        taxes
        totalSales
      }
    }
  }
`);
