import { gql } from '~anyx/common/graphql';

export const TOKOPEDIA_SALES_CHART = gql(/* GRAPHQL */ `
  query TokopediaSalesChart($input: TokopediaSalesChartInput!) {
    tokopediaSalesChart(input: $input) {
      currency
      orders
      sales
    }
  }
`);
