import { gql } from '~anyx/common/graphql';

export const LAZADA_SALES_CHART = gql(/* GRAPHQL */ `
  query LazadaSalesChart($input: LazadaSalesChartInput!) {
    lazadaSalesChart(input: $input) {
      conversionRate
      currency
      orders
      sales
      unitsOrdered
      visitors
    }
  }
`);
