import { gql } from '~anyx/common/graphql';

export const RAKUTEN_SALES_REPORT_SUMMARY = gql(/* GRAPHQL */ `
  query RakutenSalesReportSummary($input: RakutenSalesReportSummaryInput!) {
    rakutenSalesReportSummary(input: $input) {
      conversionRate {
        diff
        diffPercentage
        value
      }
      currency
      orderCount {
        diff
        diffPercentage
        value
      }
      sessions {
        diff
        diffPercentage
        value
      }
      totalSales {
        diff
        diffPercentage
        value
      }
    }
  }
`);
