import { gql } from '~anyx/common/graphql';

export const SHOPIFY_SALES_REPORT_CHART = gql(/* GRAPHQL */ `
  query ShopifySalesReportChart($input: ShopifySalesReportChartInput!) {
    shopifySalesReportChart(input: $input) {
      currency
      discounts
      grossSales
      netSales
      orderCount
      returns
      shipping
      taxes
      totalSales
    }
  }
`);
