import { useQuery } from '@apollo/client';

import { AdDatasourceType } from '~anyx/common/graphql';
import { useFeatureFlag } from '~anyx/external/firebase';

import { AD_DATASOURCES_LIST } from '../graphql';
import { adDatasourceTypes } from '../utils';



export const useCheckAdDatasource = ({ masterDataStoreId = '' }: { masterDataStoreId?: string }) => {
  const enableAdDatasourceV2 = useFeatureFlag('rp_enable_ad_datasource_v2')?.value;
  const { data, loading } = useQuery(AD_DATASOURCES_LIST, {
    skip: !masterDataStoreId || !enableAdDatasourceV2,
    variables: {
      input: {
        masterDataStoreId,
        datasourceTypes: [],
      },
    },
  });

  const existingDatasources = (data?.adDatasources?.items || []).map((ds) => ds.type);

  const isSelectDisabled = (type: AdDatasourceType) => {
    // Advertisement channel case

    // User can add only one channel for the same type.
    // (i.e. If user adds one GoogleAds account, user cannot add another GoogleAds account)
    return existingDatasources.includes(type);
  };

  return {
    isSelectDisabled,
    isAdvertisementButtonDisabled: adDatasourceTypes.every((ds) => existingDatasources.includes(ds)),
    loading,
  };
};
