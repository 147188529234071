import { useTranslation } from 'react-i18next';

import { AnyXLogoIcon } from '~anyx/shared/icons';

import { ProgressBar } from '../../../../../../components';

export const SettingGuideHeader = ({ progressPercentage }: { progressPercentage: number }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <div className="card mr-2 rounded-[0.625rem] p-2.5">
          <AnyXLogoIcon className="h-[1.25rem] w-[1.25rem]" />
        </div>
        <p className="font-semibold">
          {t('core.page.home.settingGuide.anyXSettingGuide', { ns: 'core' })}
        </p>
      </div>
      <div className="flex items-center">
        <ProgressBar
          label={t('core.page.home.settingGuide.finished', {
            ns: 'core',
            value: progressPercentage,
          })}
          value={progressPercentage}
        />
      </div>
    </div>
  );
};
