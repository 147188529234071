import { gql } from '~anyx/common/graphql';

export const REPORT_DOCUMENT_DETAIL = gql(/* GRAPHQL */ `
  query reportDocumentDetail($id: Long!) {
    reportDocument(id: $id) {
      id
      status
      url
    }
  }
`);
