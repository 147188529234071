import { useTranslation } from 'react-i18next';

import { useAsyncEffect } from 'use-async-effect';

import { useIsMerchant, useIsUnderDDIAccount } from '~anyx/common/permission';
import { RestrictedWorkspaceMode, useWorkspaceCtx, WorkspaceMode } from '~anyx/common/workspace';
import { ChatInput } from '~anyx/function/report/components';
import { useAiChatFeatureFlag, useDefaultAccount } from '~anyx/function/report/hooks';
import { PageLayout, Spinner } from '~anyx/shared/ui';

import {
  DDIReportSection,
  ReportSection,
  SettingGuide,
  useSessionSettingGuide,
  useSettingGuide,
  CsvUpdateBannerTmp,
} from './components';
import { InactiveChannelBanner } from './InactiveChannelBanner';
import { Tips } from './Tips';

export const HomePage = () => {
  const { t } = useTranslation('core');
  const { workspace, mode } = useWorkspaceCtx();
  const { isUnderDDIAccount } = useIsUnderDDIAccount(
    workspace?.accountId ? [workspace.accountId] : []
  );
  const { isMerchant } = useIsMerchant();
  const { defaultAccountIds, loading: accountLoading } = useDefaultAccount(
    'multiple',
    workspace?.accountId,
    isMerchant && isUnderDDIAccount
  );

  const enabledAiChat = useAiChatFeatureFlag();

  const {
    showSettingGuide,
    setStoreVisitedIds,
    isStoreVisited,
    hideSettingGuide,
    isHiddenSettingGuide,
  } = useSessionSettingGuide();

  const {
    hasConnectedSaleChannel,
    hasInputUnitCost,
    hasConnectedDatasourceChannel,
    hasSaleChannelCostIndex,
    hasShopifyChannel,
    shouldShowMock,
  } = useSettingGuide();

  useAsyncEffect(async () => {
    // When “Connect sales channel“ option is fulfilled, keep the onboarding card closed by default AXRP-1226
    if (isStoreVisited || !hasConnectedSaleChannel) return;
    hideSettingGuide();
    setStoreVisitedIds();
  }, [hasConnectedSaleChannel, workspace]);

  return (
    <PageLayout
      fullPage
      clamped
      title={{
        text: t('core.page.home.title'),
      }}
      loading={accountLoading}
      loadingComponent={
        <div className="m-auto flex h-full w-full flex-col">
          <Spinner />
        </div>
      }
    >
      <div className="lg:p-6">
        <article className="grid grid-cols-1 gap-4 xl:flex xl:gap-6">
          <div className="xl:cols-span-none col-span-1 md:mt-0 xl:flex-grow">
            <CsvUpdateBannerTmp />
            <RestrictedWorkspaceMode restrictedTo={[WorkspaceMode.ON]}>
              <InactiveChannelBanner storeId={workspace?.storeId} />
              {!isHiddenSettingGuide && (
                <SettingGuide
                  wrapperClassName="hidden lg:block"
                  hasSalesChannels={hasConnectedSaleChannel}
                  hasDatasourcesChannels={hasConnectedDatasourceChannel}
                  hasInputUnitCost={hasInputUnitCost}
                  hasShopifyChannel={hasShopifyChannel}
                  hasInputSaleChannelCostIndex={hasSaleChannelCostIndex}
                  hideSettingGuide={hideSettingGuide}
                />
              )}
            </RestrictedWorkspaceMode>
            {isMerchant && isUnderDDIAccount ? (
              <DDIReportSection
                shouldShowMock={shouldShowMock}
                hasSalesChannels={hasConnectedSaleChannel}
                accountId={workspace?.accountId}
                storeId={workspace?.storeId}
                mode={mode}
              />
            ) : (
              defaultAccountIds.length !== 0 && (
                <ReportSection
                  shouldShowMock={shouldShowMock}
                  hasSalesChannels={hasConnectedSaleChannel}
                  accountIds={defaultAccountIds}
                  storeId={workspace?.storeId}
                  mode={mode}
                />
              )
            )}
          </div>
          <div className="xl:col-span-none col-span-1 xl:w-[14.25rem] xl:flex-shrink-0">
            <Tips
              isHiddenSettingGuide={isHiddenSettingGuide}
              showSettingGuide={showSettingGuide}
              className="card rounded-none border-b bg-white lg:rounded"
            />
          </div>
        </article>
      </div>
      {enabledAiChat && <ChatInput />}
    </PageLayout>
  );
};
