import { cloneElement, ReactNode, SVGProps } from 'react';
import { Translation } from 'react-i18next';

import { CheckIcon, MantineColor } from '@any-ui-react/core';

import { AdDatasourceStatus, AdDatasourceType, DatasourceType } from '~anyx/common/graphql';
import {
  CrossIcon,
  FacebookFaIcon,
  GoogleAdsFaIcon,
  GoogleAnalyticsFaIcon,
  LogoFacebookAdsIcon,
  LogoGoogleAdsIcon,
  LogoGoogleAnalytics4Icon,
  LogoYahooAdsIcon,
  TikTokFaIcon,
  TikTokIcon,
  WarningIcon,
  YahooFaIcon,
} from '~anyx/shared/icons';
import { ObjectUtils } from '~anyx/shared/utils';

import { AdvertisementDatasources, AnalyticsDatasources } from './datasource.config';

const DATASOURCE_ICON: Record<
  DatasourceType,
  { fa: React.ReactElement; logo: React.ReactElement }
> = {
  [DatasourceType.FacebookAds]: {
    fa: <FacebookFaIcon />,
    logo: <LogoFacebookAdsIcon />,
  },
  [DatasourceType.GoogleAds]: {
    fa: <GoogleAdsFaIcon />,
    logo: <LogoGoogleAdsIcon />,
  },
  [DatasourceType.TikTokAds]: {
    fa: <TikTokFaIcon />,
    logo: <TikTokIcon />,
  },
  [DatasourceType.GoogleAnalytics4]: {
    fa: <GoogleAnalyticsFaIcon />,
    logo: <LogoGoogleAnalytics4Icon />,
  },
  [DatasourceType.YahooSearchAds]: {
    fa: <YahooFaIcon />,
    logo: (
      <div className="flex justify-center gap-2">
        <LogoYahooAdsIcon className="h-8 w-[10.5rem]" />
        <span className="text-gray-9 text-md font-bold leading-9 md:text-lg">
          <Translation ns="gql">
            {(t) => (
              <>{t('gql.enum.yahooAdsType', { ns: 'gql', type: DatasourceType.YahooSearchAds })}</>
            )}
          </Translation>
        </span>
      </div>
    ),
  },
  [DatasourceType.YahooDisplayAds]: {
    fa: <YahooFaIcon />,
    logo: (
      <div className="flex justify-center gap-2">
        <LogoYahooAdsIcon className="h-8 w-[10.5rem]" />
        <span className="text-gray-9 text-md font-bold leading-9 md:text-lg">
          <Translation ns="gql">
            {(t) => (
              <>{t('gql.enum.yahooAdsType', { ns: 'gql', type: DatasourceType.YahooDisplayAds })}</>
            )}
          </Translation>
        </span>
      </div>
    ),
  },
};

const AD_DATASOURCE_ICON: Record<
  AdDatasource,
  { fa: React.ReactElement; logo: React.ReactElement }
> = {
  [AdDatasourceType.FACEBOOK_ADS]: {
    fa: <FacebookFaIcon />,
    logo: <LogoFacebookAdsIcon />,
  },
  [AdDatasourceType.GOOGLE_ADS]: {
    fa: <GoogleAdsFaIcon />,
    logo: <LogoGoogleAdsIcon />,
  },
  [AdDatasourceType.TIKTOK_ADS]: {
    fa: <TikTokFaIcon />,
    logo: <TikTokIcon />,
  },
  [AdDatasourceType.YAHOO_SEARCH_ADS]: {
    fa: <YahooFaIcon />,
    logo: (
      <div className="flex justify-center gap-2">
        <LogoYahooAdsIcon className="h-8 w-[10.5rem]" />
        <span className="text-gray-9 text-md font-bold leading-9 md:text-lg">
          <Translation ns="gql">
            {(t) => (
              <>{t('gql.enum.yahooAdsType', { ns: 'gql', type: DatasourceType.YahooSearchAds })}</>
            )}
          </Translation>
        </span>
      </div>
    ),
  },
  [AdDatasourceType.YAHOO_DISPLAY_ADS]: {
    fa: <YahooFaIcon />,
    logo: (
      <div className="flex justify-center gap-2">
        <LogoYahooAdsIcon className="h-8 w-[10.5rem]" />
        <span className="text-gray-9 text-md font-bold leading-9 md:text-lg">
          <Translation ns="gql">
            {(t) => (
              <>{t('gql.enum.yahooAdsType', { ns: 'gql', type: DatasourceType.YahooDisplayAds })}</>
            )}
          </Translation>
        </span>
      </div>
    ),
  },
};

export const getDatasourceIcon = (
  datasource: DatasourceType,
  type: 'fa' | 'logo',
  props?: SVGProps<SVGElement>
) => {
  return cloneElement(DATASOURCE_ICON[datasource][type], props);
};

export const isAdvertisementDatasource = (value: DatasourceType) => {
  return AdvertisementDatasources.includes(value);
};

export const isAnalyticsDatasource = (value: DatasourceType) => {
  return AnalyticsDatasources.includes(value);
};

export const getAdDatasourceIcon = (
  datasource: AdDatasourceType,
  type: 'fa' | 'logo',
  props?: SVGProps<SVGElement>
) => {
  const adDatasource = AdDatasourceUtils.asAdDatasourceType(datasource);
  return adDatasource ? cloneElement(AD_DATASOURCE_ICON[adDatasource][type], props) : null;
};

export const AD_DATASOURCE_SYNC_STATUS: Record<
  AdDatasourceStatus,
  {
    color: MantineColor;
    i18nKey: string;
    icon: ReactNode;
  }
> = {
  [AdDatasourceStatus.SUCCESS]: {
    color: 'green',
    i18nKey: 'shared.button.connected',
    icon: <CheckIcon className="text-success mr-1 w-3" />,
  },
  [AdDatasourceStatus.FAILED]: {
    color: 'red',
    i18nKey: 'shared.button.disconnected',
    icon: <CrossIcon className="text-failure mr-1 w-3" />,
  },
  [AdDatasourceStatus.FAILED_PERMANENTLY]: {
    color: 'red',
    i18nKey: 'shared.button.disconnected',
    icon: <CrossIcon className="text-failure mr-1 w-3" />,
  },
  [AdDatasourceStatus.IN_PROGRESS]: {
    color: 'yellow',
    i18nKey: 'shared.button.syncing',
    icon: <WarningIcon className="text-warning mr-1 w-3" />,
  },
  [AdDatasourceStatus.NOT_CONFIGURED]: {
    color: 'gray',
    i18nKey: 'shared.button.notConfigured',
    icon: <WarningIcon className="text-warning mr-1 w-3" />,
  },
  [AdDatasourceStatus.WAITING_SYNC]: {
    color: 'gray',
    i18nKey: 'shared.button.pending',
    icon: <WarningIcon className="text-warning mr-1 w-3" />,
  },
  [AdDatasourceStatus.THROTTLED]: {
    color: 'gray',
    i18nKey: 'shared.button.pending',
    icon: <WarningIcon className="text-warning mr-1 w-3" />,
  },
};

export const AdDatasource = {
  FACEBOOK_ADS: AdDatasourceType.FACEBOOK_ADS,
  GOOGLE_ADS: AdDatasourceType.GOOGLE_ADS,
  TIKTOK_ADS: AdDatasourceType.TIKTOK_ADS,
  YAHOO_SEARCH_ADS: AdDatasourceType.YAHOO_SEARCH_ADS,
  YAHOO_DISPLAY_ADS: AdDatasourceType.YAHOO_DISPLAY_ADS,
};

export type AdDatasource = (typeof AdDatasource)[keyof typeof AdDatasource];

export const adDatasourceTypeMapping: Record<AdDatasource, DatasourceType> = {
  [AdDatasourceType.FACEBOOK_ADS]: DatasourceType.FacebookAds,
  [AdDatasourceType.GOOGLE_ADS]: DatasourceType.GoogleAds,
  [AdDatasourceType.TIKTOK_ADS]: DatasourceType.TikTokAds,
  [AdDatasourceType.YAHOO_SEARCH_ADS]: DatasourceType.YahooSearchAds,
  [AdDatasourceType.YAHOO_DISPLAY_ADS]: DatasourceType.YahooDisplayAds,
};

export const adDatasourceTypes: AdDatasourceType[] = ObjectUtils.arrayFromEnum(AdDatasource);

export class AdDatasourceUtils {
  static isAdDatasourceType = (type = ''): type is AdDatasource => type in AdDatasource;

  static asAdDatasourceType = (type?: string) => (this.isAdDatasourceType(type) ? type : undefined);
}
