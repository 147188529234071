import { gql } from '~anyx/common/graphql';

export const AMAZON_SALES_CHART = gql(/* GRAPHQL */ `
  query AmazonSalesChart($input: AmazonSalesChartInput!) {
    amazonSalesChart(input: $input) {
      averageNumberOfUnitsPerOrderItem
      currency
      itemAverageCost
      itemsOrdered
      sales
      unitAverageCost
      unitsOrdered
    }
  }
`);
