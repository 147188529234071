import { gql } from '~anyx/common/graphql';

export const SHOPEE_SALES_SUMMARY = gql(/* GRAPHQL */ `
  query ShopeeSalesSummary($input: ShopeeSalesSummaryInput!) {
    shopeeSalesSummary(input: $input) {
      currency
      orders {
        diff
        diffPercentage
        value
      }
      sales {
        diff
        diffPercentage
        value
      }
      salesPerOrder {
        diff
        diffPercentage
        value
      }
      visitors {
        diff
        diffPercentage
        value
      }
    }
  }
`);
