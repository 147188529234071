import { gql } from '~anyx/common/graphql';

export const MD_STORE_CHANNELS = gql(/* GRAPHQL */ `
  query MdStoreChannels($mdStoreId: Long!) {
    MdStore(id: $mdStoreId) {
      channels {
        id
        type
      }
    }
  }
`);
